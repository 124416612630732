import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import axios from 'axios';
import { apiUrl, uploadUrl } from '../../config';
import ErrorView from '../../components/errorView';
import { customermanagecreate, region_search, territory_search, beat_search, state_search, district_search, city_search, customersingle, customermanageupdate } from '../../actions';
import { errorRender, successRedriect, validate, getValue, inputCheck } from '../../helpers/functions';
import { ProgressBar } from 'react-bootstrap';

const Inputfield = lazy(() => import('../../components/inputfields'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Buttons = lazy(() => import('../../components/buttons'));
const Links = lazy(() => import('../../components/links'));
const Userdropdown = lazy(() => import('../userManagement/userDropdwon'));
const Loader = lazy(() => import('../../components/loader'));
const Zonedropdown = lazy(() => import('../zoneManagement/zoneDropdwon'));
const RegionDropdown = lazy(() => import('../regionManagement/RegionDropdown'));
const Territorydropdown = lazy(() => import('../territoryManagement/territoryDropdown'));
const Beatdropdown = lazy(() => import('../beatsManagement/beatDropdwon'));
const CountryDropdown = lazy(() => import('../../components/address/countrydropdown'));
const StateDropdown = lazy(() => import('../../components/address/stateDropdown'));
const CityDropdown = lazy(() => import('../../components/address/citydropdown'));
const SelectInputField = lazy(() => import('../../components/selectInputField'));
const TextAreaInputField = lazy(() => import('../../components/textAreaInputField'));
class Customer_manage_add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageType: 'add',
            itemId: '',
            name: "",
            code: "",
            company: "",
            email: "",
            mobile: "",
            beat: null,
            territory: null,
            businessCard: [],
            region: null,
            zone: null,
            shopName: "",
            marketName: "",
            customerType: "",
            ownersName: "",
            accountCode: "",
            ownersContactNumber: "",
            uploadPercentage: 0,
            shopErpId: "",
            shopSegmentation: "",
            tag: "",
            agent: null,
            country: null,
            state: null,
            district: null,
            city: "",
            line1: "",
            line2: "",
            postalCode: "",
            online_offline_customer: '',
            serverErrors: ["customer_create_res", "customer_update", "customer_single"],
            forRedirect: [
                { name: "customer_create_res", pageName: 'Customer', pageUrl: 'customermanagement', action: "Added" },
                { name: "customer_update", pageName: 'Customer', pageUrl: 'customermanagement', action: "Updated" }
            ],
            setFields: [
                { name: "name", stateName: "name", type: "text" },
                { name: "code", stateName: "code", type: "text" },
                { name: "accountCode", stateName: "accountCode", type: "text" },
                { name: "mobile", stateName: "mobile", type: "text" },
                { name: "email", stateName: "email", type: "text" },
                { name: "zone", stateName: "zone", type: "select" },
                { name: "region", stateName: "region", type: "select" },
                { name: "territory", stateName: "territory", type: "select" },
                { name: "beat", stateName: "beat", type: "select" },
                { name: "shopName", stateName: "shopName", type: "text" },
                { name: "customerType", stateName: "customerType", type: "text" },
                { name: "isOnline", stateName: "online_offline_customer", type: "text" },
                { name: "ownersContactNumber", stateName: "ownersContactNumber", type: "text" },
                { name: "shopSegmentation", stateName: "shopSegmentation", type: "text" },
                { name: "marketName", stateName: "marketName", type: "text" },
                { name: "ownersName", stateName: "ownersName", type: "text" },
                { name: "shopErpId", stateName: "shopErpId", type: "text" },
                { name: "tag", stateName: "tag", type: "text" },
                { name: "address.country", stateName: "country", type: "select" },
                { name: "address.state", stateName: "state", type: "select" },
                { name: "address.city", stateName: "city", type: "select" },
                { name: "address.line1", stateName: "line1", type: "text" },
                { name: "address.line2", stateName: "line2", type: "text" },
                { name: "address.postalCode", stateName: "postalCode", type: "text" },
                { name: "agent", stateName: "agent", type: "select" },
                { name: "isNoUpcharge", stateName: "isNoUpcharge", type: "text" },
                { name: "businessCard", stateName: "businessCard", type: "text" },
                { name: "gstNum", stateName: "gstNum", type: "text" },
                { name: "adminRemarks", stateName: "adminRemarks", type: "text" },
                { name: "isBlackList", stateName: "isBlackList", type: "text" }
            ],
            adminRemarks: "",
            isBlackList: false,
            nameError: '',
            codeError: '',
            emailError: '',
            phoneError: '',
            zoneError: '',
            regionError: '',
            territoryError: '',
            beatError: '',
            agentError: '',
            countryError: '',
            stateError: '',
            accountCodeError: '',
            districtError: '',
            cityError: '',
            customerTypeError: '',
            shopNameError: '',
            marketNameError: '',
            ownersNameError: '',
            ownersContactNumberError: '',
            shopErpIdError: '',
            shopSegmentationError: '',
            online_offline_customerError: '',
            tagError: '',
            line1Error: '',
            line2Error: '',
            postalCodeError: '',
            isLoading: false,
            customerTypeOptions: [
                { label: "Select a Customer Type", value: "" },
                { label: "Retailer", value: "retailer" },
                { label: "Wholesaler", value: "wholesaler" }
            ],
            isOnlineOptions: [
                { label: "Select online/offline Type", value: "" },
                { label: "Online", value: true },
                { label: "Offline", value: false }
            ],
            getSingleLoader: false,
            isNoUpcharge: false,
            gstNum: ""
        }
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.customersingle(id);
        }
    }

    handleChange = (name, e) => {
        // console.log(name);
        let isValid = inputCheck(name, e.target.value);

        if (isValid) {
            this.setState({ [name]: e.target.value });
        }
        // const re = /([^\s]+)/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     this.setState({ [name]: e.target.value });
        // }
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    ondropdownChangeagent = (e, t) => {
        this.setState({ [e]: t, utype: t.type });
        if (t.type === "AG") {
            this.setState({ customerType: "retailer" });
        } else if (t.type === "ASM" || t.type === "WHS") {
            this.setState({ customerType: "wholesaler" });
        } else {
            if (this.state.pageType !== "update") {
                this.setState({ customerType: "" });
            }
        }
    }

    checkhandler = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
    }

    ondropdownChange = (val, name) => {
        if (val && val.value) {
            if (name === "zone") {
                if (this.state.zone !== null) {
                    if (val.value !== this.state.zone.value) {
                        this.setState({ region: null, territory: null, beat: null });
                        let data = {
                            zone: val.value
                        };
                        this.props.region_search(data);
                    }
                } else {
                    let data = {
                        zone: val.value
                    };
                    this.props.region_search(data);
                }
            }
            if (name === "region") {
                if (this.state.region !== null) {
                    if (val.value !== this.state.region.value) {
                        this.setState({ territory: null, beat: null });
                        let data = {
                            region: val.value
                        };
                        this.props.territory_search(data);
                    }
                } else {
                    let data = {
                        region: val.value
                    };
                    this.props.territory_search(data);
                }
            }
            if (name === "territory") {
                if (this.state.territory !== null) {
                    if (val.value !== this.state.territory.value) {
                        this.setState({ beat: null });
                        let data = {
                            territory: val.value
                        };
                        this.props.beat_search(data);
                    }
                } else {
                    let data = {
                        territory: val.value
                    };
                    this.props.beat_search(data);
                }
            }
            if (name === "country") {
                if (this.state.country !== null) {
                    if (val.value !== this.state.country.value) {
                        this.setState({ state: null, city: null });
                        let data = {
                            country: val.value
                        };
                        this.props.state_search(data)
                    }
                } else {
                    let data = {
                        country: val.value
                    };
                    this.props.state_search(data)
                }
            }
            if (name === "state") {
                if (this.state.state !== null) {
                    if (val.value !== this.state.state.value) {
                        this.setState({ city: null });
                        let data = {
                            state: val.value
                        };
                        this.props.city_search(data);
                    }
                } else {
                    let data = {
                        state: val.value
                    };
                    this.props.city_search(data);
                }
            }
            this.setState({ [name]: val });
        }
    }

    onsubmit = (e) => {

        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'code', label: "Code", value: this.state.code, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 50, type: "text", message: "", errName: "codeError" },
            { name: 'email', label: "Email", value: this.state.email, hasError: false, isRequired: false, isNumber: false, onlyChar: false, type: "email", message: "", errName: "emailError" },
            { name: 'accountCode', label: "Account Code", value: this.state.accountCode, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 50, type: "text", message: "", errName: "accountCodeError" },
            { name: 'customerType', label: "Customer Type", value: this.state.customerType, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "text", message: "", errName: "customerTypeError" },
            { name: 'online_offline_customer', label: "Is Online", value: this.state.online_offline_customer, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "text", message: "", errName: "online_offline_customerError" },
            { name: 'agent', label: "User", value: this.state.agent, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "agentError" },
            { name: 'country', label: "Country", value: this.state.country, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "countryError" },
            { name: 'state', label: "State", value: this.state.state, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "stateError" },
            { name: 'city', label: "City", value: this.state.city, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "cityError" },
            { name: 'postalCode', label: "Zip Code", value: this.state.postalCode, hasError: false, isRequired: true, isNumber: false, onlyChar: false, minLength: 8, maxLength: 8, type: "text", message: "", errName: "postalCodeError" }
        ];

        let data = {
            name: this.state.name,
            code: this.state.code,
            email: this.state.email,
            mobile: this.state.mobile,
            beat: this.state.beat && this.state.beat.value,
            territory: this.state.territory && this.state.territory.value,
            region: this.state.region && this.state.region.value,
            zone: this.state.zone && this.state.zone.value,
            shopName: this.state.shopName,
            marketName: this.state.marketName,
            customerType: this.state.customerType,
            accountCode: this.state.accountCode,
            ownersName: this.state.ownersName,
            isOnline: this.state.online_offline_customer === "true" ? true : this.state.online_offline_customer === "false" ? false : this.state.online_offline_customer,
            ownersContactNumber: this.state.ownersContactNumber,
            shopErpId: this.state.shopErpId,
            shopSegmentation: this.state.shopSegmentation,
            tag: this.state.tag,
            agent: this.state.agent && this.state.agent.value,
            isActive: true,
            adminRemarks: this.state.adminRemarks,
            isBlackList: this.state.isBlackList,
            isNoUpcharge: this.state.isNoUpcharge,
            address: {
                country: this.state.country && this.state.country.value,
                state: this.state.state && this.state.state.value,
                city: this.state.city && this.state.city.value,
                line1: this.state.line1,
                line2: this.state.line2,
                postalCode: this.state.postalCode
            },
            businessCard: this.state.businessCard,
            gstNum: this.state.gstNum
        };

        e.preventDefault();

        let { newArr, valid } = validate(validationFields);

        newArr.map((el) => this.setState({ [el.errName]: el.message }));

        if (valid) {
            this.setState({ isLoading: true });
            if (this.state.pageType === "update") {
                this.props.customermanageupdate(data, this.state.itemId);
            } else {
                if (data && !data.shopName) {
                    data.shopName = data.name;
                }
                this.props.customermanagecreate(data);
            }
            validationFields = null;
            newArr = null;
        }

    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false })
            }
            if (nextProps.customer_single && nextProps.customer_single.success === true) {
                nextProps.customer_single.success = null;
                this.setState({ getSingleLoader: false })
                if (nextProps.customer_single.item) {
                    this.state.setFields.forEach((value) => {
                        if (value.type === "text") {
                            this.setState({ [value.stateName]: getValue(nextProps.customer_single.item, value.name) })
                        } else if (value.type === "select" && getValue(nextProps.customer_single.item, value.name)) {
                            if (value.name === "agent") {
                                let obj = {
                                    label: nextProps.customer_single.item[value.name].name,
                                    value: nextProps.customer_single.item[value.name]._id,
                                    type: nextProps.customer_single.item[value.name].type
                                }
                                this.ondropdownChangeagent(value.name, obj);
                                obj = null
                            } else {
                                let obj = {
                                    label: getValue(nextProps.customer_single.item, value.name).name,
                                    value: getValue(nextProps.customer_single.item, value.name)._id
                                }
                                this.ondropdownChange(obj, value.stateName);
                                obj = null
                            }
                        }
                    })
                    nextProps.customer_single.success = null;
                }
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
        }
        return true;
    }

    uploadFile = ({ target: { files } }) => {
        // console.log(files[0])
        let data = new FormData();
        data.append('file', files[0])
        const token = localStorage.getItem('token');
        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)
                console.log(`${loaded}kb of ${total}kb | ${percent}%`);

                if (percent < 100) {
                    this.setState({ uploadPercentage: percent })
                }
            }
        }
        axios.defaults.headers = { 'x-access-token': token }
        axios.post(apiUrl + "/api/customers/upload", data, options).then(res => {
            // console.log(res)
            this.setState({ businessCard: res.data.item.businessCard, uploadPercentage: 100 }, () => {
                setTimeout(() => {
                    this.setState({ uploadPercentage: 0 })
                }, 1000);
            })
        })
    }

    render() {
        const { pageType, isLoading, getSingleLoader, uploadPercentage } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title={pageType === "update" ? "Update Customer" : "Add Customer"}/>
                                </div>
                                {getSingleLoader ? <Loader /> :
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group required col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.nameError} maxlength="100" handleChange={this.handleChange} type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="emailHelp" placeholder="Name" />
                                            </div>
                                            <div className="form-group required col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.codeError} handleChange={this.handleChange} maxlength="10" type="text" name="code" value={this.state.code} className="form-control" id="code" aria-describedby="emailHelp" placeholder="Code" />
                                            </div>
                                            <div className="form-group required col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.accountCodeError} handleChange={this.handleChange} maxlength="50" type="text" name="accountCode" value={this.state.accountCode} className="form-control" id="accountCode" aria-describedby="emailHelp" placeholder="Account Code (ERP Accountcode)" />
                                            </div>
                                            <div className="form-group  col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.phoneError} handleChange={this.handleChange} type="text" maxlength="10" name="mobile" value={this.state.mobile} className="form-control" id="mobile" placeholder="Mobile" />
                                            </div>
                                            <div className="form-group col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.emailError} handleChange={this.handleChange} maxlength="100" type="text" name="email" value={this.state.email} className="form-control" id="email" placeholder="Email" />
                                            </div>
                                            <div className=" form-group  col-12 col-lg-4">
                                                <Zonedropdown
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "zone")}
                                                    placeholder="Search Zone"
                                                    value={this.state.zone}
                                                    errMessage={this.state.zoneError}
                                                />
                                            </div>
                                            <div className=" form-group  col-12 col-lg-4">
                                                <RegionDropdown
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "region")}
                                                    placeholder="Search Region"
                                                    dontPreload={pageType === "update"}
                                                    value={this.state.region}
                                                    errMessage={this.state.regionError}
                                                />
                                            </div>
                                            <div className="form-group  col-12 col-lg-4">
                                                <Territorydropdown
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "territory")}
                                                    placeholder="Search Territory"
                                                    value={this.state.territory}
                                                    dontPreload={pageType === "update"}
                                                    errMessage={this.state.territoryError}
                                                />
                                            </div>
                                            <div className=" form-group  col-12 col-lg-4">
                                                <Beatdropdown
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "beat")}
                                                    placeholder="Search Beat"
                                                    value={this.state.beat}
                                                    dontPreload={pageType === "update"}
                                                    errMessage={this.state.beatError}
                                                />
                                            </div>
                                            <div className="form-group col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.shopNameError} handleChange={this.handleChange} type="text" maxlength="50" name="shopName" value={this.state.shopName} className="form-control" id="shopName" placeholder="Shop Name" />
                                            </div>
                                            <div className="form-group required col-12 col-lg-4">
                                                <Userdropdown
                                                    ondropdownChange={this.ondropdownChangeagent}
                                                    type='CUSTOMER'
                                                    name="agent"
                                                    value={this.state.agent}
                                                    className="form-control form-control-square"
                                                    placeholder="User"
                                                    errMessage={this.state.agentError}
                                                />
                                            </div>
                                            <div className="form-group required col-12 col-lg-4">
                                                <SelectInputField label="Customer Type" errMessage={this.state.customerTypeError}
                                                    onChange={this.onChange}
                                                    name="customerType" value={this.state.customerType}
                                                    disabled={["ASM", "AG", "WHS"].indexOf(this.state.utype) > -1 ? true : false} className="form-control" options={this.state.customerTypeOptions} />
                                            </div>
                                            <div className="form-group required col-12 col-lg-4">
                                                <SelectInputField label="Is online ?" errMessage={this.state.online_offline_customerError}
                                                    onChange={this.onChange} name="online_offline_customer" id="online_offline_customer" value={this.state.online_offline_customer} read className="form-control" options={this.state.isOnlineOptions} />
                                            </div>
                                            <div className="form-group col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.ownersContactNumberError} handleChange={this.handleChange} maxlength="10" type="text" name="ownersContactNumber" value={this.state.ownersContactNumber} className="form-control" id="ownersContactNumber" placeholder="Owner's Contact Number" />
                                            </div>
                                            <div className="form-group  col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.shopSegmentationError} handleChange={this.handleChange} type="text" maxlength="50" name="shopSegmentation" value={this.state.shopSegmentation} className="form-control" id="shopSegmentation" placeholder="Shop Segmentation" />
                                            </div>

                                            <div className="form-group  col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.marketNameError}
                                                    handleChange={this.handleChange} type="text" maxlength="50" name="marketName" value={this.state.marketName} className="form-control" id="marketName" placeholder="Market Name" />
                                            </div>
                                            <div className="form-group col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.ownersNameError}
                                                    handleChange={this.handleChange} type="text" name="ownersName" value={this.state.ownersName} className="form-control" id="ownersName" placeholder="Owners Name" />
                                            </div>
                                            <div className="form-group col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.shopErpIdError}
                                                    handleChange={this.handleChange} type="text" name="shopErpId" value={this.state.shopErpId} className="form-control" id="shopErpId" placeholder="Shop Erpld" />
                                            </div>
                                            <div className="form-group col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.tagError}
                                                    handleChange={this.handleChange} maxlength="10" type="text" name="tag" value={this.state.tag} className="form-control" id="tag" placeholder="Tag" />
                                            </div>
                                            <div className="form-group col-12 col-lg-4">
                                                <label className="tgl-btn m-0" htmlFor="isNoUpcharge">No UpCharge</label>
                                                <input className="tgl tgl-light tgl-warning" name="isNoUpcharge" checked={this.state.isNoUpcharge} id="isNoUpcharge" type="checkbox" onChange={(e) => this.checkhandler(e)} />
                                                <label className="tgl-btn m-3" htmlFor="isNoUpcharge"></label>
                                            </div>
                                            {
                                                this.state.businessCard && this.state.businessCard.length !== 0 ?
                                                    <div className="form-group  col-12 col-lg-3">
                                                        {/* {console.log(uploadUrl + this.state.businessCard.medium)} */}
                                                        <label htmlFor="businessCard" className="control-label">Business Card</label>
                                                        <div className="document-media">
                                                            <div onClick={() => { this.setState({ businessCard: [] }) }} className="close-btn"> <i className="fa fa-times"></i></div>
                                                            <img alt="img" className="img" src={uploadUrl + this.state.businessCard.medium} />
                                                            <div className="media-content">
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    <div className="form-group  col-12 col-lg-3">
                                                        <label htmlFor="businessCard" className="control-label">Business Card</label>
                                                        <input
                                                            maxLength="5"
                                                            onChange={this.uploadFile}
                                                            // value={this.state.code}
                                                            name="businessCard"
                                                            type="file"
                                                            className="form-control"
                                                            accept='.jpeg, .jpg, .png'
                                                        />
                                                        <ErrorView message={this.state.businessCardError} />
                                                        <div className="progressbar-image">
                                                            {uploadPercentage > 0 && <ProgressBar now={uploadPercentage} active label={`${uploadPercentage}%`} />}
                                                        </div>
                                                    </div>
                                            }

                                            <div className="form-group col-12 col-lg-4">
                                                <Inputfield maxlength="100" handleChange={this.handleChange} type="text" name="gstNum" value={this.state.gstNum} className="form-control" id="gstNum" ariadescribedby="gstHelp" placeholder="GST Number" />
                                            </div>

                                            <div className="form-group  col-12 col-lg-4">
                                                <TextAreaInputField errMessage="" onChange={this.onChange} name="adminRemarks" id="adminRemarks" maxLength="5000" value={this.state.adminRemarks} className="form-control" rows="2" placeholder="Admin Remarks for Customers" />
                                            </div>

                                            <div className="form-group col-12 col-lg-4">
                                                <label className="tgl-btn m-0 text-danger" htmlFor="isBlackList">Red Listed?</label>
                                                <input className="tgl tgl-light tgl-warning" name="isBlackList" checked={this.state.isBlackList} id="isBlackList" type="checkbox" onChange={(e) => this.checkhandler(e)} />
                                                <label className="tgl-btn m-3" htmlFor="isBlackList"></label>
                                            </div>

                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    {getSingleLoader ? null :
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-shadow mb-4">
                                    <div className="card-header">
                                        <div className="card-title">
                                            {pageType === "update" ? "Update Address" : "Add Address"}
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className=" form-group required col-12 col-lg-4">
                                                <CountryDropdown
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "country")}
                                                    placeholder="Search Country"
                                                    value={this.state.country}
                                                    errMessage={this.state.countryError}
                                                />
                                            </div>
                                            <div className=" form-group required col-12 col-lg-4">
                                                <StateDropdown
                                                    dontPreload={pageType === "update"}
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "state")}
                                                    placeholder="Search State"
                                                    value={this.state.state}
                                                    country={this.state.country}
                                                    errMessage={this.state.stateError}
                                                />
                                            </div>
                                            <div className=" form-group required col-12 col-lg-4">
                                                <CityDropdown
                                                    dontPreload={pageType === "update"}
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "city")}
                                                    placeholder="Search City"
                                                    value={this.state.city}
                                                    state={this.state.state}
                                                    country={this.state.country}
                                                    errMessage={this.state.cityError}
                                                />
                                            </div>
                                            <div className="form-group  col-12 col-lg-4">
                                                <TextAreaInputField errMessage={this.state.line1Error} onChange={this.onChange} name="line1" id="line1" maxLength="5000" value={this.state.line1} className="form-control" rows="2" placeholder="Address line-1" />
                                            </div>
                                            <div className="form-group  col-12 col-lg-4 ">
                                                <TextAreaInputField errMessage={this.state.line2Error} onChange={this.onChange} name="line2" maxLength="5000" value={this.state.line2} className="form-control" id="line2" rows="2" placeholder="Address line-2" />
                                            </div>
                                            <div className="form-group required col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.postalCodeError} handleChange={this.handleChange} type="text" maxlength="8" name="postalCode" value={this.state.postalCode} className="form-control" id="postalCode" placeholder="Zip Code" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
                <div className="row">
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                        <Links to={routehelp.customermanagement} className="btn btn-outline-dark" name="Cancel" />
                    </div>
                </div>
            </main >
        )
    }
}

function mapStateToProps(state) {
    return {
        customer_create_res: state.customer_create_res,
        customer_single: state.customer_single,
        customer_update: state.customer_update
    };
}


const mapDispatchToProps = dispatch => ({
    customersingle: data => dispatch(customersingle(data)),
    customermanagecreate: data => dispatch(customermanagecreate(data)),
    region_search: data => dispatch(region_search(data)),
    territory_search: data => dispatch(territory_search(data)),
    beat_search: data => dispatch(beat_search(data)),
    state_search: data => dispatch(state_search(data)),
    district_search: data => dispatch(district_search(data)),
    city_search: data => dispatch(city_search(data)),
    customermanageupdate: (data, id) => dispatch(customermanageupdate(data, id)),
});

Customer_manage_add = connect(
    mapStateToProps,
    mapDispatchToProps
)(Customer_manage_add);

export default Customer_manage_add;
import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import Inputfield from '../../components/inputfields';
import Userdropdown from '../userManagement/userDropdwon';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import { routesingle, region_search, territory_search, beat_search, customer_search, routecreate, routeupdate } from '../../actions';
import Select from 'react-select';
import Moment from 'moment';
import { errorRender, optionsList, successRedriect } from '../../helpers/functions';
import ErrorView from '../../components/errorView';
const CardTitle = lazy(() => import('../../components/cardTitle'));
class Routeupdate extends Component {
    state = {
        name: '',
        agent: '',
        dayLimit: 0,
        startdate: '',
        enddate: '',
        customer: [],
        customer_array: [],
        value: [],
        serverError: ["route_update", "route_single"],
        error: {
            nameError: '',
            userError: '',
            customerError: '',
            dateError: ''
        },
        isLoading: false,
        minStartDate: new Date()
    }


    componentDidMount() {
        let id = this.props.match.params.item_id;
        this.props.routesingle(id);

    }

    componentWillReceiveProps(props) {
        if (props.route_single && props.route_single.success) {
            props.route_single.success = null;
            this.setState({ value: optionsList(props.route_single.customers), agent: props.route_single.agent._id });
            let data = {
                agent: props.route_single.agent._id
            }
            this.props.customer_search(data);
            this.setState({
                name: props.route_single.item
                    ? props.route_single.item.name
                    : '',
                dayLimit: props.route_single.item
                    ? props.route_single.item.dayLimit
                    : 0,
                customer_array:
                    props.route_single.customers
                        ? props.route_single.customers
                        : '',
                startdate: props.route_single.item && props.route_single.item.startDate ? Moment(props.route_single.item.startDate).format('YYYY-MM-DD') : '',
                enddate: props.route_single.item && props.route_single.item.endDate ? Moment(props.route_single.item.endDate).format('YYYY-MM-DD') : '',
                srAsmSelection: { value: props.route_single && props.route_single.srAsm && props.route_single.srAsm._id ? props.route_single.srAsm._id : '', label: props.route_single !== undefined && props.route_single.srAsm && props.route_single.srAsm.name ? props.route_single.srAsm.name : '' }
            }, () => {
                this.minDateValue();
            });
        }
        return false;
    }

    handleChange = (name, e) => {
        const re = /^\d*$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: Number(e.target.value) });
        }
    }


    ondropdownChange = (e, t) => {
        this.setState({ [e]: t.value, agent: t.value });
        let data = {
            agent: t.value
        }
        this.setState({ value: [], customer: [], customer_array: [] });
        this.props.customer_search(data);
    }

    validate = () => {
        let nameError = '';
        let userError = '';
        let customerError = '';
        let dateError = '';
        if (this.state.name === "") {
            nameError = "Name is Required";
        }
        if ((this.state.agent && this.state.agent.length === 0) || this.state.agent === null) {
            userError = 'User Is Required';
        }
        if (this.state.customer_array && this.state.customer_array.length === 0) {
            customerError = 'Customer Is Required';
        }
        if (this.state.startdate === "" || this.state.enddate === "") {
            dateError = 'Date Is Required'
        }
        if (nameError || userError || customerError || dateError) {
            this.setState({ nameError, userError, customerError, dateError });
            return false;
        }
        return true;
    };

    ondropdownChangezone = (e, t) => {
        this.setState({ [e]: t.value });
        let data = {
            zone: t.value
        };
        this.props.region_search(data)
    }


    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
    };

    startdate = (startdate) => this.setState({ startdate });

    enddate = enddate => this.setState({ enddate })

    onsubmit = (e) => {
        let id = this.props.match.params.item_id;
        let data = {
            name: this.state.name,
            agent: this.state.agent,
            customers: this.state.customer_array,
            startDate: this.state.startdate,
            endDate: this.state.enddate,
            dayLimit: this.state.dayLimit,
            company: '',
            isActive: true
        }
        e.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            this.setState(this.state.error);
            this.setState({ isLoading: true });
            this.props.routeupdate(data, id);
        }


    }

    multicustomer = (value) => {
        let temp = []
        if (value !== undefined && value !== null && value[value.length - 1]) {
            value.forEach((items) => {
                temp.push({
                    _id: items.value,
                    name: items.label
                })
            })
            this.setState({ customer_array: temp, value })
        } else {
            this.setState({ customer_array: [], value })
        }
        // return value;
    }

    checkDateDisabledOrNot = () => {
        let today = new Date().toISOString().slice(0, 10).replace(/-/g, "-");
        let previousDate = this.state.enddate;

        if (today > previousDate) {
            return true;
        } else {
            return false;
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            this.state.serverError.forEach((el) => errorRender(nextProps, el));
            successRedriect(nextProps, "route_update", "Plan", this.props, "routeplanning", "Updated");
            if (nextProps.customer_response_search !== undefined && nextProps.customer_response_search.success === true) {
                this.setState({ customer: optionsList(nextProps.customer_response_search.list) });
                nextProps.customer_response_search.success = null
            }
        }
        return true;
    }


    selectAllCus = () => {
        this.setState({
            value: [...this.state.customer]
        }, () => {
            let temp = [];
            this.state.value.forEach((items) => {
                temp.push({
                    _id: items.value,
                    name: items.label
                })
            });
            this.setState({ customer_array: temp })
        });
    }

    minDateValue = () => {

        let days7before = new Date(this.state.startdate);
        days7before.setDate(days7before.getDate() - 7);

        if ((days7before.getMonth() + 1) < 10 && days7before.getDate() < 10) {
            this.setState({
                minStartDate: `${days7before.getFullYear()}-0${days7before.getMonth() + 1}-0${days7before.getDate()}`
            });
        } else if ((days7before.getMonth() + 1) < 10) {
            this.setState({
                minStartDate: `${days7before.getFullYear()}-0${days7before.getMonth() + 1}-${days7before.getDate()}`
            });
        } else if (days7before.getDate() < 10) {
            this.setState({
                minStartDate: `${days7before.getFullYear()}-${days7before.getMonth() + 1}-0${days7before.getDate()}`
            });
        } else {
            this.setState({
                minStartDate: `${days7before.getFullYear()}-${days7before.getMonth() + 1}-${days7before.getDate()}`
            });
        }
    }

    render() {
        const { route_single } = this.props;
        const { isLoading } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title="Update Route" />
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="form-group col-12 col-lg-4">
                                            <Inputfield errMessage={this.state.nameError} disabled={true} type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Tour Name" />
                                        </div>

                                        <div className="form-group col-12 col-lg-4">
                                            <label className="control-label">{"Sr. ASM"} </label>
                                            <Select
                                                isDisabled={true}
                                                onChange={this.srAsmChange}
                                                value={this.state.srAsmSelection}
                                                options={this.state.srASMoptions}
                                                placeholder={"Select SrASM"}
                                                // isMulti={isMulti}
                                                isSearchable={true}
                                                isClearable={true}
                                            />
                                            <ErrorView message={this.state.userError} />
                                        </div>
                                        <div className="form-group required col-12 col-lg-4">
                                            <Userdropdown
                                                isDisabled={true}
                                                ondropdownChange={this.ondropdownChange}
                                                name="agent"
                                                value={this.state.agent}
                                                className="form-control"
                                                placeholder={route_single !== undefined && route_single.agent ? route_single.agent.name : ''}
                                            />
                                        </div>
                                        <div className="form-group required col-12 col-lg-4 ">
                                            <label className="control-label">Customer </label>
                                            <Select placeholder='Select Customer' closeMenuOnSelect={false} onChange={this.multicustomer} options={this.state.customer} isMulti={true} value={this.state.value} isSearchable={true} />
                                            <ErrorView message={this.state.customerError} />
                                        </div>

                                        <div className="form-group  col-12 col-lg-4">
                                            <label className="control-label d-block">&nbsp;</label>
                                            <button type='button' name='selectall' className='btn btn-primary' onClick={(e) => this.selectAllCus()}>Select All</button>
                                        </div>

                                        <div className="form-group required col-12 col-lg-4">
                                            <label className="control-label">From Date</label>
                                            <input
                                                type="date"
                                                onChange={this.onChange}
                                                onKeyDown={(e) => e.preventDefault()}
                                                value={this.state.startdate}
                                                className="form-control"
                                                name='startdate'
                                                min={this.state.minStartDate}
                                            />
                                        </div>

                                        <div className="form-group required col-12 col-lg-4">
                                            <label className="control-label" >To Date</label>
                                            <input type="date" className="form-control" name="enddate" onKeyDown={(e) => e.preventDefault()}
                                                min={this.state.startdate} onChange={this.onChange} value={this.state.enddate} />
                                        </div>

                                        <div className="form-group col-12 col-lg-4">
                                            <Inputfield handleChange={this.handleChange} type="number" name="dayLimit" value={this.state.dayLimit} className="form-control" id="dayLimit" ariadescribedby="codeHelp" placeholder="Day Limit" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading} loading={isLoading} name="Update" />
                            <Links to={routehelp.routeplanning} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        route_single: state.route_single,
        customer_response_search: state.customer_response_search,
        route_update: state.route_update
    };
}


const mapDispatchToProps = dispatch => ({
    routesingle: data => dispatch(routesingle(data)),
    region_search: data => dispatch(region_search(data)),
    territory_search: data => dispatch(territory_search(data)),
    beat_search: data => dispatch(beat_search(data)),
    customer_search: data => dispatch(customer_search(data)),
    routecreate: data => dispatch(routecreate(data)),
    routeupdate: (data, id) => dispatch(routeupdate(data, id))
});

Routeupdate = connect(
    mapStateToProps,
    mapDispatchToProps
)(Routeupdate);

export default Routeupdate;